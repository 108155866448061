<template>
  <div class="col-12 p-md-3 p-2">
    <div class="row justify-content-center align-items-center" >
      <div class="col-11 col-sm-8 col-md-auto text-center bold">
        <div class="tab text-center w-100">
          <div class="row m-0 justify-content-center align-items-center">
            <div class="col-6 col-md-auto" v-for="option, key in tabOptions" :key="key" @click="update(option)" :class="{active: modelValue === option}">
              <div class="row m-0 justify-content-center align-items-center">
                <div class="col-auto p-0 text-uppercase">
                  {{option}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Tabs Components',
  props: ['tabOptions', 'modelValue'],
  emits: ['update:modelValue'],

  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    update (val) {
      this.value = val
    }
  }
}
</script>

<style scoped >
.tab {
  border-radius: 50px;
  padding: 3px;
  background-color: var(--green-color-dark);
  color: #fff;
  border: 3px solid var(--green-color);
  font-size: 11px;
  cursor: pointer;
}
.tab .active {
  background-color: var(--gold-color);
  color: var(--green-color-dark);
  border-radius: 50px;
  cursor: default;
}
</style>
